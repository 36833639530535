import { Component, Injector } from '@angular/core';
import { Data } from '@angular/router';
// Rxjs
import { first } from 'rxjs/operators';
// Caloudi
import { BaseComponent } from '@base';
import environment from '@env';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass'],
})
export class HomeComponent extends BaseComponent {
  constructor(public readonly injector: Injector) {
    super(injector);

    this.route.data.pipe(first()).subscribe((data: DirectData) => {
      const user = this.authenticationService.currentUser.value;
      if (!user) {
        this.router.navigate(['/login']);
        return;
      }
      if (this.router.url === '/reload') return;
      const homeRoutherLink = user ? user.userMenu.homeRouterLink : environment.homeRouterLink;
      this.router.navigate([data?.redirectTo?.length > 0 ? '/pages/' + data.redirectTo : homeRoutherLink]);

      this.logger.debug('home:', [homeRoutherLink, data, user], 'from:', [this.router.routerState.snapshot.url]);
    });
  }
}

interface DirectData extends Data {
  redirectTo: string;
  path: string;
}
